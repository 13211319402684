@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

.menu-card {
  @apply flex flex-col justify-center items-center bg-white h-screen font-mono py-40;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}

body {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}